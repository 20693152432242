import clsx from 'clsx';
import { FC } from 'react';
import arrow from '@/assets/icons/arrowRight.svg';
import layoutStyles from '@/layouts/MainLayout/Layout.module.scss';
import { useBlockScroll, useJump } from '@/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { PageTypes } from '../../../Layout';

import styles from './Menu.module.scss';
import { useSelector } from '@/store';

interface Props {
  variant: 'home' | 'career' | boolean;
  page: PageTypes;
  isOpen: boolean;
  close: () => void;
  openSendResume: () => void;
  renderLanguage: () => React.ReactNode;
  renderSocials: () => React.ReactNode;
}

export const Menu: FC<Props> = ({
  isOpen,
  close,
  variant,
  page,
  openSendResume,
  renderLanguage,
  renderSocials,
}) => {
  const navigate = useNavigate();
  const jump = useJump();
  const isBlockScroll = useBlockScroll();
  const { activeSections } = useSelector((state) => state.activeSections);

  const careerMenu = [
    {
      name: 'Ценности',
      ref: 'career-values',
      active: true,
    },
    {
      name: 'Команда',
      ref: 'team',
      active: true,
    },
    {
      name: 'Вакансии',
      ref: 'jobs',
      active: true,
    },
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  const homeMenu = [
    {
      name: 'Технологии',
      ref: 'services',
      active: true,
    },
    {
      name: 'Аутcтаффинг',
      ref: 'benefits',
      active: activeSections.benefits,
    },
    // {
    //   name: 'Аутсорсинг',
    //   ref: 'autsors',
    //   active: activeSections.autsors,
    // },
    {
      name: 'Наши кейсы',
      ref: 'cases',
      active: activeSections.autsors,
    },
    {
      name: 'Отзывы',
      ref: 'review',
      active: true,
    },
    {
      name: 'Наша команда',
      ref: 'career',
      active: true,
    },
    {
      name: 'Достижения',
      ref: 'achievements',
      active: true,
    },
    {
      name: 'Сертификаты',
      ref: 'certificates',
      active: true,
    },
    {
      name: 'Контакты',
      ref: 'contacts',
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  const instructionMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
  ];

  const casesMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  const blogMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  const vacanciesMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  const massMediaMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
  ];

  const otherPagesMenu = [
    {
      name: 'О нас',
      link: '/',
      laptop: true,
      active: true,
    },
    {
      name: 'Кейсы',
      link: '/cases/all',
      laptop: true,
      active: activeSections.cases,
    },
    {
      name: 'Карьера',
      link: '/career',
      laptop: true,
      active: true,
    },
    {
      name: 'Блог',
      link: '/blogs/all',
      laptop: true,
      active: activeSections.blogs,
    },
    {
      name: 'Вакансии',
      link: '/vacancies/all',
      laptop: true,
      active: true,
    },
    {
      name: 'СМИ',
      link: '/mass-media',
      laptop: true,
      active: activeSections.mass_media,
    },
  ];

  let list = homeMenu.filter((menuItem) => menuItem.active);
  const isCareer =
    (typeof variant === 'string' && variant === 'career') ||
    (typeof variant === 'boolean' && page === 'career') ||
    (typeof variant === 'boolean' && page === 'instruction');

  if (isCareer) {
    list = careerMenu.filter((menuItem) => menuItem.active);
  }

  if (page === 'cases' && !isBlockScroll) {
    list = casesMenu.filter((menuItem) => menuItem.active);
  }

  if (page === 'blogs' && !isBlockScroll) {
    list = blogMenu.filter((menuItem) => menuItem.active);
  }

  if (page === 'vacancies' && !isBlockScroll) {
    list = vacanciesMenu.filter((menuItem) => menuItem.active);
  }

  if (page === 'mass-media' && !isBlockScroll) {
    list = massMediaMenu.filter((menuItem) => menuItem.active);
  }

  if (page === 'instruction' && !isBlockScroll) {
    list = instructionMenu.filter((menuItem) => menuItem.active);
  }
  if (
    (page === 'blog-details' ||
      page === 'case-details' ||
      page === 'vacancy-details') &&
    !isBlockScroll
  ) {
    list = otherPagesMenu.filter((menuItem) => menuItem.active);
  }

  const click = (block: string) => {
    if (typeof variant !== 'boolean' && page !== variant) {
      if (
        page === 'instruction' ||
        page === 'cases' ||
        page === 'case-details' ||
        page === 'blogs' ||
        page === 'blog-details' ||
        page === 'vacancies' ||
        page === 'vacancy-details' ||
        page === 'mass-media'
      ) {
        navigate({
          pathname: variant === 'home' ? '/' : '/career',
          search: `?block=${block}`,
        });
      } else {
        navigate({
          pathname: page === 'home' ? '/career' : '/',
          search: `?block=${block}`,
        });
      }
    } else {
      jump(block);
    }
  };

  const clickSend = async () => {
    return new Promise((res) => {
      res(navigate('/'));
    }).then(() => jump('request'));
  };

  return (
    <nav
      className={clsx(styles['nav-drop'], layoutStyles.container, {
        [styles['nav-drop-opened']]: isOpen,
      })}
    >
      {isOpen && (
        <>
          <ul className={clsx(styles['nav-drop__list'])}>
            {list.map((link, id) => (
              <li
                key={id}
                className={clsx(
                  styles['nav-drop__item'],
                  styles[`item${id + 1}`],
                  {
                    [styles['nav-drop__item-laptop']]: link.laptop,
                  }
                )}
                onClick={
                  link.ref
                    ? () => {
                        click(link.ref);
                        close();
                      }
                    : () => close()
                }
              >
                {link.link ? (
                  <Link to={link.link}>
                    {link.name}
                    <img alt="" src={arrow} />
                  </Link>
                ) : (
                  link.name
                )}
              </li>
            ))}
          </ul>

          <div className={clsx(styles['nav-drop__footer'])}>
            <div className={styles['nav-drop__footer-mobile']}>
              <div className={clsx(styles['nav-drop__footer-mobile-language'])}>
                {renderLanguage()}
              </div>
              <div className={clsx(styles['nav-drop__footer-socials'])}>
                {renderSocials()}
              </div>
            </div>
            <button
              className={clsx(styles['nav-drop__btn'])}
              onClick={() => {
                if (isCareer) {
                  openSendResume();
                } else {
                  page === 'instruction' ||
                  page === 'cases' ||
                  page === 'blogs' ||
                  page === 'case-details' ||
                  page === 'blog-details' ||
                  page === 'mass-media'
                    ? clickSend()
                    : jump('request');
                }
                close();
              }}
            >
              {isCareer ? 'Отправить резюме' : 'Оставить заявку'}
            </button>
          </div>
        </>
      )}
    </nav>
  );
};
